import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Khalid - an incoming CS Ph.D. student at the `}<a parentName="p" {...{
        "href": "https://www.umd.edu/"
      }}>{`University of Maryland, College Park`}</a>{`. I take great interest in Deep Learning research. Specifically, I'm interested in self-supervised representation learning and deep generative modeling. I care about making models broadly applicable by ensuring `}<strong parentName="p">{`robustness`}</strong>{`, `}<strong parentName="p">{`sample efficiency`}</strong>{` and `}<strong parentName="p">{`interpretability`}</strong>{`.
Other than that, I'm also into visualizations, photography, aaand `}<a parentName="p" {...{
        "href": "https://letterboxd.com/khalidsaifullah/"
      }}>{`cinema`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      