import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Talks & Workshops" mdxType="Title" />
    <ul style={{
      "padding": "revert"
    }}>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://youtu.be/ui0X6ozE3bI">DALL·E mini: Zero-Shot Text-to-Image Generation</a>: A talk I've given as part of my Delta Analytics graduation lecture over YouTube live.</div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://youtu.be/glSPPjZOraE">Let’s easily Gittu! Git Github Workshop by UIU APP FORUM</a>: A workshop I've conducted as an instructor for the CS freshers.</div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://youtube.com/playlist?list=PLtl_4y-2122FXGysy3F7x4dRLUPYWyAMC">OOP Workshop on Java</a>: A 5-day long workshop that I've conducted as an instructor in the trimester break, taught the basics of OOP to the students.</div></li>
    </ul>
    <br /><br />
    <Title text="Awards & Community Contributions" mdxType="Title" />
    <ul style={{
      "padding": "revert"
    }}>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Secured 1st position at <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://discuss.huggingface.co/t/open-to-the-community-community-week-using-jax-flax-for-nlp-cv/7104)">Huggingface JAX/FLAX Community Event</a> with the group project <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://wandb.ai/dalle-mini/dalle-mini/reports/DALL-E-mini--Vmlldzo4NjIxODA">DALL·E mini</a>.</div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Received <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="http://www.deltanalytics.org/global-teaching-fellows.html)">Global Teaching Fellowship</a> by Delta Analytics for 2021 session.</div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>University Merit Scholarship for academic excellence in all trimesters, UIU.</div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>One of the contributors in the <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://github.com/Atcold/pytorch-Deep-Learning/wiki/Bengali-Translation)">Bengali translation</a> of Yann LeCun and Alfredo Canziani's <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://atcold.github.io/pytorch-Deep-Learning/">Deep Learning course</a> at NYU.</div></li>
    </ul>
    <br /><br />
    <Title text="Projects" mdxType="Title" />
    <ul style={{
      "padding": "revert"
    }}>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://wandb.ai/dalle-mini/dalle-mini/reports/DALL-E-mini--Vmlldzo4NjIxODA">DALL·E mini</a>: A JAX/FLAX implementation of OpenAI’s DALL·E but ~30 times smaller and trained on a much smaller dataset to meet resource constraints. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/borisdayma/dalle-mini">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://huggingface.co/flax-community/gpt2-bengali">GPT-2 Bengali</a>: The very first implementation of GPT-2 for Bengali. Trained from scratch with the Bengali portion of <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://huggingface.co/datasets/mc4">multilingual-c4</a> dataset.<a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://huggingface.co/flax-community/gpt2-bengali/tree/main">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://huggingface.co/khalidsaifullaah/bengali-lyricist-gpt2">Bengali Lyricist</a>: A deep generative model that generates Bengali song lyrics. The model is finetuned on the <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://www.kaggle.com/shakirulhasan/bangla-song-lyrics">song lyrics dataset</a> from kaggle. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://huggingface.co/khalidsaifullaah/bengali-lyricist-gpt2/tree/main">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://github.com/khalidsaifullaah/BERTify">BERTify</a>: A python module that helps with extracting BERT embeddings for large Benglai/English dataset. Especially optimized for users with limited computational access (e.g. free colab/kaggle GPUs) to tackle CUDA OOMs. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/BERTify">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://steep-cycle-f6b.notion.site/DietNeRF-Putting-NeRF-on-a-Diet-4aeddae95d054f1d91686f02bdb74745">DietNeRF</a>: First coding implementation of <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://arxiv.org/abs/2104.00677">Putting NeRF on a Diet</a> paper that requires much fewer image samples than original NeRF model to perform 3D reconstruction. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/codestella/putting-nerf-on-a-diet">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://youtu.be/PDLPeBnlldU">Misogynistic Meme Classifier</a>: As part of AllenNLP Hacks 2021, trained a multimodal model for misogynous meme identification using pretrained CLIP as feature extractor. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/Tahsin-Mayeesha/allenlp-hacks">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://huggingface.co/neuropark/sahajBERT">sahajBERT</a>: The very first experiment of decentralized collaborative training with an Albert-like model for Bengali. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/yandex-research/DeDLOC/tree/main/sahajbert">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://khalidsaifullaah.github.io/pathfinding-visualizer/">Path Finder</a>: A web app that helps to visualize the typical graph searching algorithm such as Dijkstra, A*, etc. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/pathfinding-visualizer/">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://khalidsaifullaah.github.io/8-Puzzle-A-Star-Search/">8-Puzzle Solver</a>: The 8-puzzle game with the ability to get it solved by the (A* heuristics based) A.I, made as part of the AI course project. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/8-Puzzle-A-Star-Search/">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://github.com/khalidsaifullaah/BOOKy">BOOKy</a>: A website for book lovers to lend and borrow books independently. Made with Django as part of System Analysis and Design course project. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/BOOKy/">(source code)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://github.com/khalidsaifullaah/One-Step">One Step</a>: A website that helps user to create a social cause and also gets people engage in them as volunteers or donators. Made as part of Database Management System course project. <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/One-Step">(source code)</a></div></li>
    </ul>
    <div style={{
      "fontStyle": "italic",
      "fontSize": "1.25rem"
    }}>See more of my projects on <a style={{
        "color": "#9f7aea",
        "fontSize": "1.25rem"
      }} href="https://github.com/khalidsaifullaah">Github</a></div><br /><br />
    <Title text="MOOCs & Certifications" mdxType="Title" />
    <ul style={{
      "padding": "revert"
    }}>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Deep Learning Summer School by Neuromatch Academy <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://portal.neuromatchacademy.org/certificate/166995e9-55ca-4162-85d5-f1c8cb9f8492">(certificate)</a> <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/sazio/NMAs">(projects)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Computational Neuroscience Summer School by Neuromatch Academy <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://portal.neuromatchacademy.org/certificate/abd3a73d-4760-43dc-829a-98f201a7c8ee">(certificate)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Harvard CS50's AI course <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://certificates.cs50.io/17b46456-2ce0-4919-b775-5efbd28a348a.pdf?size=letter">(certificate)</a> <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/CS50-AI-Projects">(projects)</a></div></li>
      <li style={{
        "listStyleType": "disc"
      }}> <div>Codeacademy's Data Science career path <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://www.codecademy.com/profiles/khalidsaifullaah/certificates/5b520caa1d176d21f5a65a61">(certificate)</a> <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem",
            "fontStyle": "italic"
          }} href="https://github.com/khalidsaifullaah/CS50-AI-Projects">(projects)</a></div></li>
    </ul>
    <br /><br /><br /><br />
    {
      /*  <Title text="My Works?" />
      - <div>You can find them <a style="color:#9f7aea; font-size:1.25rem;" href="/portfolio">here</a></div><br/><br/><br/> */
    }
    <Title text="Connect Me" mdxType="Title" />
    <ul>
      <li parentName="ul">
        <div>You can reach me at - <a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="mailto:ksaifullah172043@bscse.uiu.ac.bd">ksaifullah172043@bscse.uiu.ac.bd</a></div><br /><br />
      </li>
    </ul>
    <Title text="Links" mdxType="Title" />
    <ul>
      <li parentName="ul">
        <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://drive.google.com/file/d/1RboETara3b6SJxkdIvh29ZvKz_GXnPVX/view?usp=sharing"> Resume</a></div>
      </li>
      <li parentName="ul">
        <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://www.linkedin.com/in/khalidsaifullaah/">Linkedin</a></div>
      </li>
      <li parentName="ul">
        <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://stackoverflow.com/users/7610724/khalid-saifullah"> Stack Overflow</a></div>
      </li>
      <li parentName="ul">
        <div><a style={{
            "color": "#9f7aea",
            "fontSize": "1.25rem"
          }} href="https://www.youtube.com/channel/UCCeefeCIxdGpYgsG4o3VqAw"> Youtube</a></div>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      